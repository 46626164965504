import moment from 'moment';
import {authRole} from 'shared/constants/AppConst';

export const createRoutes = (routeConfigs) => {
  let allRoutes = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return {...route, auth};
    });
  }

  return [...routes];
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      console.log('key', key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const getCustomDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const checkPermission = (routeAuth, userRole) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getUserFromAuth0 = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Unknown User',
      email: user.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Unknown User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.User,
    };
  return user;
};

export const formatDateFM = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return date.length > 6 ? [day, month, year].join('.') : '';
};

export const convertPapayaDate = (date, safari) => {
  var day = date.slice(0, 2);
  var month = date.slice(3, 5);
  var year = date.slice(6, 10);

  //return [year, month, day].join('-') + `T${date.slice(11, 19)}`;
  return safari ? [year, day, month].join('-') : [year, month, day].join('-');
};

export const convertDateFM_API = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-');
};

export const formatNumberFM = (number) => {
  if (!number) {
    return '0,00';
  }

  return new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(number));
};

export const formatNumberInt = (number) => {
  if (!number) {
    return '0';
  }

  return new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(parseFloat(number));
};

export const removeEmptyLines = (string) => {
  return string.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');
};

export function getLocation({places, locationRef, onResponse}) {
  const autocomplete = new places.Autocomplete(locationRef.current, {
    types: ['(cities)'],
  });

  autocomplete.setFields(['formatted_address', 'address_components']);
  autocomplete.addListener('place_changed', function () {
    const result = autocomplete.getPlace();

    if (!result.address_components) return;
    return onResponse(formatLocation(result, locationRef));
  });
}

export function getLocality({places, locationRef, onResponse}) {
  const autocomplete = new places.Autocomplete(locationRef.current, {
    types: ['establishment'],
    componentRestrictions: {country: ['sk']},
  });

  autocomplete.setFields([
    'name',
    'formatted_address',
    'formatted_phone_number',
    'geometry',
    'place_id',
    'website',
    'opening_hours',
    'price_level',
  ]);
  autocomplete.addListener('place_changed', function () {
    const result = autocomplete.getPlace();

    if (!result.name) return;
    return onResponse(formatLocality(result, locationRef));
  });
}

// get country from google map response and location
// from the selected autocomplete text
export function formatLocation(result, locationRef) {
  const data = {
    location: '',
    country: '',
    city: '',
    region: '',
    countryShort: '',
  };
  data.location = locationRef.current.value;

  for (const addressComponent of result.address_components) {
    if (addressComponent.types[0] === 'country') {
      data.country = addressComponent.long_name;
      data.countryShort = addressComponent.short_name;
    }
    if (addressComponent.types[0] === 'locality') {
      data.city = addressComponent.short_name;
    }

    if (addressComponent.types[0] === 'administrative_area_level_1') {
      data.region = addressComponent.short_name;
    }
  }
  return data;
}

export function formatLocality(result) {
  const data = {
    //location: '',
    google_place_id: '',
    name: '',
    web_link: '',
    phone_number: '',
    address: '',
    lng: '',
    lat: '',
    opening_hours: [],
  };
  //data.location = locationRef.current.value;
  data.google_place_id = result.place_id;
  data.name = result.name;
  data.web_link = result.website;
  data.phone_number = result.formatted_phone_number;
  data.address = result.formatted_address;
  data.lng = result.geometry.location.lat();
  data.lat = result.geometry.location.lng();
  data.opening_hours = result.geometry.location.lng();
  try {
    data.opening_hours = result.opening_hours.periods;
  } catch (error) {
    data.opening_hours = [];
  }
  return data;
}

export function generateArrayOfYears(startYear) {
  var max = new Date().getFullYear();
  var min = new Date(
    startYear ? `${startYear}-01-01` : '2020-01-01',
  ).getFullYear();
  var years = [];

  for (var i = max; i >= min; i--) {
    let x = {id: i, name: i};
    years.push(x);
  }
  return years;
}

export function convertStringToNumber(stringNumber) {
  if (!stringNumber) {
    return 0;
  }

  let value = stringNumber;
  try {
    value = value.split('.').join('');
  } catch (error) {
    error;
  }
  try {
    value = value.split(' ').join('');
  } catch (error) {
    error;
  }
  try {
    value = value.replace(',', '.');
  } catch (error) {
    error;
  }

  return parseFloat(value);
}

export function removeSpacesAndDiacritic(string) {
  const unicodeToAsciiMap = {
    Ⱥ: 'A',
    Æ: 'AE',
    Ꜻ: 'AV',
    Ɓ: 'B',
    Ƀ: 'B',
    Ƃ: 'B',
    Ƈ: 'C',
    Ȼ: 'C',
    Ɗ: 'D',
    ǲ: 'D',
    ǅ: 'D',
    Đ: 'D',
    Ƌ: 'D',
    Ǆ: 'DZ',
    Ɇ: 'E',
    Ꝫ: 'ET',
    Ƒ: 'F',
    Ɠ: 'G',
    Ǥ: 'G',
    Ⱨ: 'H',
    Ħ: 'H',
    Ɨ: 'I',
    Ꝺ: 'D',
    Ꝼ: 'F',
    Ᵹ: 'G',
    Ꞃ: 'R',
    Ꞅ: 'S',
    Ꞇ: 'T',
    Ꝭ: 'IS',
    Ɉ: 'J',
    Ⱪ: 'K',
    Ꝃ: 'K',
    Ƙ: 'K',
    Ꝁ: 'K',
    Ꝅ: 'K',
    Ƚ: 'L',
    Ⱡ: 'L',
    Ꝉ: 'L',
    Ŀ: 'L',
    Ɫ: 'L',
    ǈ: 'L',
    Ł: 'L',
    Ɱ: 'M',
    Ɲ: 'N',
    Ƞ: 'N',
    ǋ: 'N',
    Ꝋ: 'O',
    Ꝍ: 'O',
    Ɵ: 'O',
    Ø: 'O',
    Ƣ: 'OI',
    Ɛ: 'E',
    Ɔ: 'O',
    Ȣ: 'OU',
    Ꝓ: 'P',
    Ƥ: 'P',
    Ꝕ: 'P',
    Ᵽ: 'P',
    Ꝑ: 'P',
    Ꝙ: 'Q',
    Ꝗ: 'Q',
    Ɍ: 'R',
    Ɽ: 'R',
    Ꜿ: 'C',
    Ǝ: 'E',
    Ⱦ: 'T',
    Ƭ: 'T',
    Ʈ: 'T',
    Ŧ: 'T',
    Ɐ: 'A',
    Ꞁ: 'L',
    Ɯ: 'M',
    Ʌ: 'V',
    Ꝟ: 'V',
    Ʋ: 'V',
    Ⱳ: 'W',
    Ƴ: 'Y',
    Ỿ: 'Y',
    Ɏ: 'Y',
    Ⱬ: 'Z',
    Ȥ: 'Z',
    Ƶ: 'Z',
    Œ: 'OE',
    ᴀ: 'A',
    ᴁ: 'AE',
    ʙ: 'B',
    ᴃ: 'B',
    ᴄ: 'C',
    ᴅ: 'D',
    ᴇ: 'E',
    ꜰ: 'F',
    ɢ: 'G',
    ʛ: 'G',
    ʜ: 'H',
    ɪ: 'I',
    ʁ: 'R',
    ᴊ: 'J',
    ᴋ: 'K',
    ʟ: 'L',
    ᴌ: 'L',
    ᴍ: 'M',
    ɴ: 'N',
    ᴏ: 'O',
    ɶ: 'OE',
    ᴐ: 'O',
    ᴕ: 'OU',
    ᴘ: 'P',
    ʀ: 'R',
    ᴎ: 'N',
    ᴙ: 'R',
    ꜱ: 'S',
    ᴛ: 'T',
    ⱻ: 'E',
    ᴚ: 'R',
    ᴜ: 'U',
    ᴠ: 'V',
    ᴡ: 'W',
    ʏ: 'Y',
    ᴢ: 'Z',
    ᶏ: 'a',
    ẚ: 'a',
    ⱥ: 'a',
    æ: 'ae',
    ꜻ: 'av',
    ɓ: 'b',
    ᵬ: 'b',
    ᶀ: 'b',
    ƀ: 'b',
    ƃ: 'b',
    ɵ: 'o',
    ɕ: 'c',
    ƈ: 'c',
    ȼ: 'c',
    ȡ: 'd',
    ɗ: 'd',
    ᶑ: 'd',
    ᵭ: 'd',
    ᶁ: 'd',
    đ: 'd',
    ɖ: 'd',
    ƌ: 'd',
    ı: 'i',
    ȷ: 'j',
    ɟ: 'j',
    ʄ: 'j',
    ǆ: 'dz',
    ⱸ: 'e',
    ᶒ: 'e',
    ɇ: 'e',
    ꝫ: 'et',
    ƒ: 'f',
    ᵮ: 'f',
    ᶂ: 'f',
    ɠ: 'g',
    ᶃ: 'g',
    ǥ: 'g',
    ⱨ: 'h',
    ɦ: 'h',
    ħ: 'h',
    ƕ: 'hv',
    ᶖ: 'i',
    ɨ: 'i',
    ꝺ: 'd',
    ꝼ: 'f',
    ᵹ: 'g',
    ꞃ: 'r',
    ꞅ: 's',
    ꞇ: 't',
    ꝭ: 'is',
    ʝ: 'j',
    ɉ: 'j',
    ⱪ: 'k',
    ꝃ: 'k',
    ƙ: 'k',
    ᶄ: 'k',
    ꝁ: 'k',
    ꝅ: 'k',
    ƚ: 'l',
    ɬ: 'l',
    ȴ: 'l',
    ⱡ: 'l',
    ꝉ: 'l',
    ŀ: 'l',
    ɫ: 'l',
    ᶅ: 'l',
    ɭ: 'l',
    ł: 'l',
    ſ: 's',
    ẜ: 's',
    ẝ: 's',
    ɱ: 'm',
    ᵯ: 'm',
    ᶆ: 'm',
    ȵ: 'n',
    ɲ: 'n',
    ƞ: 'n',
    ᵰ: 'n',
    ᶇ: 'n',
    ɳ: 'n',
    ꝋ: 'o',
    ꝍ: 'o',
    ⱺ: 'o',
    ø: 'o',
    ƣ: 'oi',
    ɛ: 'e',
    ᶓ: 'e',
    ɔ: 'o',
    ᶗ: 'o',
    ȣ: 'ou',
    ꝓ: 'p',
    ƥ: 'p',
    ᵱ: 'p',
    ᶈ: 'p',
    ꝕ: 'p',
    ᵽ: 'p',
    ꝑ: 'p',
    ꝙ: 'q',
    ʠ: 'q',
    ɋ: 'q',
    ꝗ: 'q',
    ɾ: 'r',
    ᵳ: 'r',
    ɼ: 'r',
    ᵲ: 'r',
    ᶉ: 'r',
    ɍ: 'r',
    ɽ: 'r',
    ↄ: 'c',
    ꜿ: 'c',
    ɘ: 'e',
    ɿ: 'r',
    ʂ: 's',
    ᵴ: 's',
    ᶊ: 's',
    ȿ: 's',
    ɡ: 'g',
    ᴑ: 'o',
    ᴓ: 'o',
    ᴝ: 'u',
    ȶ: 't',
    ⱦ: 't',
    ƭ: 't',
    ᵵ: 't',
    ƫ: 't',
    ʈ: 't',
    ŧ: 't',
    ᵺ: 'th',
    ɐ: 'a',
    ᴂ: 'ae',
    ǝ: 'e',
    ᵷ: 'g',
    ɥ: 'h',
    ʮ: 'h',
    ʯ: 'h',
    ᴉ: 'i',
    ʞ: 'k',
    ꞁ: 'l',
    ɯ: 'm',
    ɰ: 'm',
    ᴔ: 'oe',
    ɹ: 'r',
    ɻ: 'r',
    ɺ: 'r',
    ⱹ: 'r',
    ʇ: 't',
    ʌ: 'v',
    ʍ: 'w',
    ʎ: 'y',
    ᶙ: 'u',
    ᵫ: 'ue',
    ꝸ: 'um',
    ⱴ: 'v',
    ꝟ: 'v',
    ʋ: 'v',
    ᶌ: 'v',
    ⱱ: 'v',
    ⱳ: 'w',
    ᶍ: 'x',
    ƴ: 'y',
    ỿ: 'y',
    ɏ: 'y',
    ʑ: 'z',
    ⱬ: 'z',
    ȥ: 'z',
    ᵶ: 'z',
    ᶎ: 'z',
    ʐ: 'z',
    ƶ: 'z',
    ɀ: 'z',
    œ: 'oe',
    ₓ: 'x',
  };
  const stringWithoutAccents = string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return stringWithoutAccents
    .replace(
      /[^\u0000-\u007E]/g, //eslint-disable-line
      (character) => unicodeToAsciiMap[character] || '',
    )
    .split(' ')
    .join('');
}

export function allTrue(obj) {
  for (var o in obj) if (!obj[o]) return false;

  return true;
}
