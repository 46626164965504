export const authRole = {
  Admin: ['admin'],
  User: ['user'],
  Infopoint: ['infopoint'],
  Accountant: ['accountant'],
};

export const RoutePermittedRole = {
  Admin: 'admin',
  User: 'user',
  Infopoint: 'infopoint',
  Accountant: 'accountant',
};

export const fileStackKey = 'Ach6MsgoQHGK6tCaq5uJgz';
export const initialUrl = '/dashboards/notifications';
export const host = 'vkoffice.sk';
export const database = 'Visitkosice';

const breakpoints = {
  0: 'xs',
  600: 'sm',
  960: 'md',
  1280: 'lg',
  1920: 'xl',
};

export default breakpoints;

export const dashbordMonthNames = [
  {label: 'Všetko', id: 0},
  {label: 'Január', id: 1},
  {label: 'Február', id: 2},
  {label: 'Marec', id: 3},
  {label: 'Apríl', id: 4},
  {label: 'Máj', id: 5},
  {label: 'Jún', id: 6},
  {label: 'Júl', id: 7},
  {label: 'August', id: 8},
  {label: 'September', id: 9},
  {label: 'Október', id: 10},
  {label: 'November', id: 11},
  {label: 'December', id: 12},
];

export const monthList = [
  {label: 'Január', id: 1},
  {label: 'Február', id: 2},
  {label: 'Marec', id: 3},
  {label: 'Apríl', id: 4},
  {label: 'Máj', id: 5},
  {label: 'Jún', id: 6},
  {label: 'Júl', id: 7},
  {label: 'August', id: 8},
  {label: 'September', id: 9},
  {label: 'Október', id: 10},
  {label: 'November', id: 11},
  {label: 'December', id: 12},
];

export const quarterList = [
  {label: '1 štvrťrok', id: 1},
  {label: '2 štvrťrok', id: 2},
  {label: '3 štvrťrok', id: 3},
  {label: '4 štvrťrok', id: 4},
];
