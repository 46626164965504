import React from 'react';
import Box from '@mui/material/Box';

import {styled} from '@mui/material/styles';
//import AppLogo from '@main/core/AppLayout/components/AppLogo';
//import logo from '../../../../assets/icon/visit_logo.png';
//import logoWhite from 'assets/icon/visit-kosice-logo-white.png';
import {useThemeContext} from '@main/utility/AppContextProvider/ThemeContextProvider';

import {useAuthUser} from '@main/utility/AuthHooks';

const LogoWrapper = styled('div')(({theme}) => {
  return {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
    [theme.breakpoints.up('xl')]: {
      top: 60,
    },
  };
});

const LogoPanel = () => {
  const {themeMode} = useThemeContext();
  const {user} = useAuthUser();
  return (
    <Box
      sx={{
        position: 'relative',
        height: user.logoUrlWhite ? '100%' : '62px',
        alignItems: 'center',
        userSelect: 'none',
      }}
    >
      {user.logoUrlWhite ? (
        <img
          style={{
            width: '90px',
            height: '60px',
            objectFit: 'scale-down',
          }}
          src={themeMode === 'dark' ? user.logoUrlDark : user.logoUrlWhite}
          alt='visit-logo'
        />
      ) : null}

      <LogoWrapper>{/* <AppLogo /> */}</LogoWrapper>
    </Box>
  );
};

export default LogoPanel;
