import {
  FETCH_ERROR,
  FETCH_START,
  SHOW_MESSAGE,
  FETCH_SUCCESS,
  GET_PURCHASE_LIST,
  GET_PURCHASE_DETAIL,
  GET_PURCHASE_VALUE_LIST,
  UPDATE_PURCHASE_LIST_ITEM,
  //UPDATE_PURCHASE_DETAIL,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetPurchaseList = (request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/purchaseList/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_PURCHASE_LIST,
            payload: data.data['response'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
            noRecordsFound: true,
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PURCHASE_LIST, payload: emtyPayload});
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onUpdatPurchaseListItem = (response) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PURCHASE_LIST_ITEM,
      payload: response,
    });
  };
};

export const onGetPurchaseValueList = (companyId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/purchaseList/script/purchaseValueList?&script.param=${companyId}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_PURCHASE_VALUE_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetPurchaseDetail = (id, showMessage) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(`/layouts/purchaseDetail/records/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_PURCHASE_DETAIL,
            payload: data.data['response'],
          });
          if (showMessage) {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Údaje boli uložené',
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

// export const onUpdatePurchaseDetail = (id) => {
//   return (dispatch) => {
//     const {messages} = appIntl();
//     dispatch({type: FETCH_START});

//     fmAxios.defaults.headers.common = {
//       Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
//     };

//     fmAxios
//       .get(`/layouts/purchaseDetail/records/${id}`)
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           sessionStorage.setItem(
//             'auth-token-timestamp',
//             JSON.stringify(new Date()),
//           );
//           dispatch({
//             type: GET_PURCHASE_DETAIL,
//             payload: data.data['response'],
//           });
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({type: FETCH_ERROR, payload: error.message});
//       });
//   };
// };
