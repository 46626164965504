import {
  GET_PURCHASE_LIST,
  GET_PURCHASE_DETAIL,
  GET_PURCHASE_VALUE_LIST,
  UPDATE_PURCHASE_LIST_ITEM,
  // UPDATE_PURCHASE_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  purchaseList: [],
  scriptData: [],
  foundCount: 0,
  noRecordsFound: false,
  purchaseValueList: [],
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_LIST:
      return {
        ...state,
        purchaseList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
        scriptData: action.payload,
      };

    case GET_PURCHASE_VALUE_LIST:
      return {
        ...state,
        purchaseValueList: JSON.parse(action.payload),
      };

    case GET_PURCHASE_DETAIL:
      return {
        ...state,
        purchaseDetail: action.payload.data[0],
      };

    case UPDATE_PURCHASE_LIST_ITEM: {
      let item = action.payload;
      let recordId = action.payload.recordId;

      const updatedlist = state.purchaseList.map((e) =>
        recordId == e.recordId ? item : e,
      );

      state.purchaseList = updatedlist;

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default purchaseReducer;
