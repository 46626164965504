import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_STATISTIC_DASHBOARD,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetStatisticDashboardData = (companyId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/attandaceData/script/onGetStatisticDashboardData?&script.param=${companyId}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_STATISTIC_DASHBOARD,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
